import * as React from "react"
import Head from "next/head"
// import { useI18n } from "@/hooks"
// import { ErrorPage } from "@/globals"
import { TemplatesMapping } from "@/runtime/nodes-templates"
import { NodeParamsMapping } from "@/runtime/nodes-params"
import { logger } from "../lib/logger"
import { getEnabledMenus } from "../lib/utils"
import { getTranslations } from "../lib/get-i18n"
import { getMenus } from "../lib/get-menus"
import { getSession } from "next-auth/react"
import { drupal } from "../lib/drupal"
import { DEFAULT_JSONAPI_NODE_PARAMS } from "../lib/const"
import { redis } from "@/lib/cache"
import { query as queryBuild } from "@/lib"
import { useEffect, useState } from "react"
export const config = { amp: "hybrid" }

const enabledMenus = getEnabledMenus()

export const NodePageHtml = ({ children, node }) => {
	const [currentUrl, setCurrentUrl] = useState("")

	useEffect(() => {
		if (typeof window !== "undefined") {
			setCurrentUrl(window.location.href)
		}
	}, [])

	const internal_metatags = node?.internal_metatag || []
	const metatag_title = internal_metatags.find((tag) => tag.id === "title")
	const node_page_title =
		metatag_title?.attributes?.content?.length > 0
			? metatag_title.attributes.content
			: node?.title
	const metatags = internal_metatags.filter((tag) => tag.id !== "title")

	return (
		<React.Fragment>
			<Head>
				<title>{node_page_title}</title>
				{/* // TODO: create a MetaTags component */}
				{metatags.map((tag, key) => {
					const Tag = tag.tag
					const backendBase = process.env.NEXT_PUBLIC_DRUPAL_BASE_URL
					const frontendBase = process.env.NEXT_PUBLIC_BASE_URL

					if (tag.attributes?.href?.startsWith(backendBase)) {
						tag.attributes.href = tag.attributes.href.replace(backendBase, frontendBase)
					}

					if (tag.id === "og_url") {
						tag.attributes.content = tag.attributes.content.replace(
							backendBase,
							frontendBase
						)
					}

					if (tag.id === "canonical_url") {
						tag.attributes.href = currentUrl
					}

					return <Tag key={key} {...tag.attributes} />
				})}
			</Head>
			{children}
		</React.Fragment>
	)
}

// @todo: refactor this and extract head, leave  NodeComponent as injected prop
export default function NodePage({ node, systemRoute = {}, ...pageProps }) {
	// const { t } = useI18n()
	let nodeType = node.type

	// System routes take priority here.
	if (systemRoute?._route === "error_page") {
		nodeType = "error_page"
	}

	if (systemRoute?._route === "maintenance_page") {
		nodeType = "maintenance_page"
	}

	const NodeComponent = TemplatesMapping[nodeType] || NodeDefault
	return (
		<NodePageHtml node={node}>
			<NodeComponent node={node} systemRoute={systemRoute} {...pageProps} />
		</NodePageHtml>
	)
}

const NodeDefault = ({ node }) => {
	return (
		<div className="bg-white">
			<div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
				<div className="text-center">
					<h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">
						Ops !
					</h2>
					<h1 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
						{node?.title}
					</h1>
					<div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-14">
						<p className="text-sm text-yellow-700">
							No template found for <strong>{node?.type}</strong> !!. Make sure you have
							defined <i>module.json</i> in your package.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export async function getServerSideProps(context) {
	const { slug, ...query } = context.query
	const { locale } = context
	let joinedSlug = Array.isArray(slug) ? slug.join("/") : slug

	// Drupal router doesn't accept empty slug.
	if (!joinedSlug) {
		joinedSlug = "/"
	}

	let accessToken = null
	let uid = 0 // Anonymous
	const session = await getSession({ req: context.req })
	// @todo: only in debug
	console.log("getNodeServerSideProps Session:", session)

	if (session) {
		accessToken = session.accessToken
		uid = session?.user?.id || 0
	}

	let i18n = [],
		menus = []
	try {
		i18n = await getTranslations(locale, {
			accessToken,
			uid,
			locale,
		})
		menus = await getMenus(enabledMenus, locale, {
			auth: session,
			uid,
			locale,
		})
	} catch (err) {
		logger.error("next-router", err)
		context.res.statusCode = err?.statusCode || 500
		return {
			props: {
				session,
				node: {},
				systemRoute: {
					_route: "maintenance_page",
					status: 500,
				},
				params: null,
				i18n: i18n,
				menus: menus,
				locale: locale,
				error: { statusCode: err?.statusCode || 500 },
			},
		}
	}

	// const locale = getLocaleFromPath(joinedSlug, enabledLanguages)
	// const langprefix = locale ? `${locale}/` : ``
	// Router stuff
	let router, routerResponse
	try {
		let routerOptions = {
			// headers: {
			// 	"X-Internal-Cacheability-Debug": "true"
			// }
			// withCache: false,
			// cacheKey: `route-${uid}-${locale}-${joinedSlug}`,
		}

		if (accessToken) {
			routerOptions["withAuth"] = () => `Bearer ${accessToken}`
			routerOptions["headers"] = {
				"X-Auth-Provider": session.provider,
			}
		}

		// routerResponse = await drupal.getRoute(joinedSlug, locale, routerOptions)
		// router = await routerResponse.json()
		const cacheKey = `router:${joinedSlug} language:${locale} ${uid}`
		const cached = await redis.get(cacheKey)
		if (cached) {
			router = JSON.parse(cached)
		} else {
			routerResponse = await drupal.getRoute(joinedSlug, locale, routerOptions)
			router = await routerResponse.json()
			await redis.set(cacheKey, JSON.stringify(router), "EX", 60 * 10) // 5 mins > keep routing longer
		}
	} catch (err) {
		logger.error("next-router", err)

		context.res.statusCode = err?.statusCode || 500
		return {
			props: {
				session,
				node: {},
				systemRoute: {
					_route: "maintenance_page",
					status: 500,
				},
				params: null,
				i18n: i18n,
				menus: menus,
				locale: locale,
				error: { statusCode: err?.statusCode || 500 },
			},
		}
	}

	// Check for redirect.
	if (router?.redirect?.length) {
		const [redirect] = router.redirect
		return {
			redirect: {
				destination: redirect.to,
				permanent: redirect.status === "301",
			},
		}
	}

	// Set HTTP status code.
	context.res.statusCode = router.status

	let nodeParams = Object.assign(
		{},
		JSON.parse(JSON.stringify(NodeParamsMapping[router.jsonapi.resourceName]))
	)
	// Add internal fields
	if (nodeParams?.fields && nodeParams?.fields[router.jsonapi.resourceName]) {
		// console.log("#DEBUG BEFORE", nodeParams.fields[router.jsonapi.resourceName])
		nodeParams.fields[router.jsonapi.resourceName] =
			DEFAULT_JSONAPI_NODE_PARAMS + "," + nodeParams.fields[router.jsonapi.resourceName]
		// console.log("#DEBUG AFTER", nodeParams.fields[router.jsonapi.resourceName])
	}

	let queryParams = ""
	if (Object.keys(query).length > 0) {
		queryParams = queryBuild(query)
		nodeParams["q"] = {
			...query,
		}
	}

	if (router?.system?._query) {
		nodeParams["q"] = {
			...nodeParams["q"],
			...router?.system?._query,
		}
	}

	let node
	try {
		let nodeOptions = {
			withCache: false,
			cacheKey: `node:${router.entity.id} user:${uid} language:${locale} locale routes route_match http_response`, // @todo: access token ?
		}

		if (accessToken) {
			nodeOptions["withAuth"] = () => `Bearer ${accessToken}`
			nodeOptions["headers"] = {
				"X-Auth-Provider": session.provider,
			}
		}

		// node = await drupal.getNode(router, nodeParams, locale, joinedSlug, nodeOptions)
		let joinedSlugKey = queryParams == "" ? joinedSlug : `${joinedSlug}?${queryParams}`
		joinedSlugKey = `/${joinedSlugKey}`.replace("//", "/")
		const cacheKey = `node:${router.entity.id} bundle:${router.entity.bundle} language:${locale} user:${uid} slug:${joinedSlugKey}`
		const cached = await redis.get(cacheKey)
		if (cached) {
			node = JSON.parse(cached)
		} else {
			node = await drupal.getNode(router, nodeParams, locale, joinedSlug, nodeOptions)
			if (!node.internal_extra.cache_exclude) {
				await redis.set(cacheKey, JSON.stringify(node), "EX", 60 * 5) // 1 min > keep it fresh
			}
		}
		// @todo: invoke hooks here

		context.res.setHeader(
			"Cache-Control",
			"public, s-maxage=10, stale-while-revalidate=59"
		)

		return {
			props: {
				session,
				node: node,
				params: Object.keys(query).length > 0 ? query : null,
				i18n: i18n,
				menus: menus,
				locale: locale,
				systemRoute: {
					...(router?.system || {}),
					status: router.status,
				},
			},
		}
	} catch (err) {
		logger.error("next-node", err)
		// context.res.statusCode = err?.statusCode || 500
		// return {
		// 	props: {
		// 		session,
		// 		node: {},
		// 		params: null,
		// 		i18n: i18n,
		// 		menus: menus,
		// 		locale: locale,
		// 		error: { statusCode: err?.statusCode || 500 },
		// 	},
		// }
	}
}
